import request from './request'

//供需表
export function sdinfo(data, category, title, page, size) {
  return request({
    url: '/sd_info?category=' + category + '&title=' + title + '&page=' + page + '&size=' + size,
    method: 'GET',
    data: data
  })
}
export function sdinfoid(data, id) {
  return request({
    url: '/sd_info/' + id,
    method: 'GET',
    data: data
  })
}
export function banner(data) {
  return request({
    url: '/api/banner/getList',
    method: 'POST',
    data: data
  })
}
export function getList(data) {
  return request({
    url: '/api/tag/getList',
    method: 'POST',
    data: data
  })
}
export function getPage(data) {
  return request({
    url: '/api/news/getPage',
    method: 'POST',
    data: data
  })
}
export function newsgetDetail(data) {
  return request({
    url: '/api/news/getDetail',
    method: 'POST',
    data: data
  })
}

export function productgetPage(data) {
  return request({
    url: '/api/product/getPage',
    method: 'POST',
    data: data
  })
}
export function getDetail(data) {
  return request({
    url: '/api/product/getDetail',
    method: 'POST',
    data: data
  })
}
export function taggetList(data) {
  return request({
    url: '/api/tag/getList',
    method: 'POST',
    data: data
  })
}
export function login(data) {
  return request({
    url: '/login/account/auth',
    method: 'POST',
    data: data
  })
}
export function fileInfo(data) {
  return request({
    url: '/api/auth/fileInfo/getPage',
    method: 'POST',
    data: data
  })
}
///
export function getDetailNum(data) {
  return request({
    url: '/api/config/getDetailNum',
    method: 'POST',
    data: data
  })
}

//新闻
export function news(data, category, keyword, page, size) {
  return request({
    url: '/news?category=' + category + '&keyword=' + keyword + '&page=' + page + '&size=' + size,
    method: 'GET',
    data: data
  })
}
export function newsid(data, id) {
  return request({
    url: '/news/' + id,
    method: 'GET',
    data: data
  })
}
