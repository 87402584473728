<template>
  <div>
    <!-- 侧边点击 -->
    <div class="prompt">
      <!-- <div class="side"><img class="imga" src="../../assets/images/xfdh.png" alt=""> <img class="imgb" src="../../assets/images/z107.png" alt=""> <div class="side-prom"> <div class="cc"><img src="../../assets/images/499218cfaf52e41f522bcbb808e2497.png" alt=""><span style="margin-left:-10px;">联系我们</span></div><el-tooltip class="item" effect="dark" content="点击复制电话" placement="top"><span>电话:<i  @click="copyUrl(link1)">{{link1}}</i></span> </el-tooltip><el-tooltip class="item" effect="dark" content="点击复制电话" placement="top"><span>手机: <i  @click="copyUrl(link2)">{{link2}}</i></span> </el-tooltip></div></div>
      <div class="side"><img  class="imga" src="../../assets/images/xfewm.png" alt=""> <img class="imgb" src="../../assets/images/z109.png" alt=""> <div class="side-proma"> <img src="../../assets/images/tykf.png" alt=""><div>欢迎添加客服木木</div></div></div>
      -->
      <div>
        <el-backtop class="up-tp" bottom=" 11rem">
          <div class="up">
            <i class="el-icon-top"></i>
          </div>
        </el-backtop>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      link2: "131 6494 1418",
      link1: "0571-8810-7025",
    };
  },
  methods: {
    copyUrl(data) {
      let url = data;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "已成功复制到剪切板",
        type: "success",
      });
      oInput.remove();
    },
  },
};
</script>

<style lang="less" scoped>
.prompt {
  position: fixed;
  top: 62%;
  left: 90%;
  width: 100px;
  height: 300px;
  bottom: 150px;
  z-index: 888;
}
.side {
  height: 40px;
  background-color: #3b3f76;
  padding-top: 9px;
  width: 40px;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  margin-bottom: 5px;
  text-align: center;
  color: #1989fa;
  position: relative;
  transition: all 1.5s;
  -moz-transition: all 1.5s;
  -webkit-transition: all 1.5s;
  -o-transition: all 1.5s;
}
.side:hover {
  background-color: #fff;
  border: 1px solid #171c61;
}
.side:hover .imga {
  display: none;
}
.side:hover .imgb {
  display: block;
}
.imga {
  width: 25px;
  height: 25px;
}
.imgb {
  width: 25px;
  height: 25px;
}
.side-prom {
  width: 206px;
  height: 80px;
  padding: 1px;
  border: 1px solid #3b3f76;
  position: absolute;
  background-color: #fff;
  top: -20px;
  left: -210px;
  display: none;
  opacity: 0;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: auto;
  padding-top: 5px;
  transition: all 2s;
  -moz-transition: all 2s;
  -webkit-transition: all 2s;
  -o-transition: all 2s;
  i {
    cursor: pointer;
  }
}
.side-proma {
  width: 160px;
  height: 140px;
  padding: 1px;
  border: 1px solid #3b3f76;
  position: absolute;
  background-color: #fff;
  top: -10px;
  left: -162px;
  display: none;
  opacity: 0;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: auto;
  padding-top: 10px;
  transition: all 2s;
  -moz-transition: all 2s;
  -webkit-transition: all 2s;
  -o-transition: all 2s;
  i {
    cursor: pointer;
  }
}
.side-prom span {
  text-align: left;
  display: inline-block;
  color: #707070;
  font-size: 12px;
  width: 206px;
  padding-left: 15px;
  line-height: 20px;
  i {
    color: #171c61;
    font-size: 16px;
    font-weight: 600;
  }
}

.cc {
  display: flex;

  font-size: 14px;
  color: #818181;

  img {
    width: 20px;
    height: 20px;
    margin-left: 90px;
    vertical-align: middle;
  }
  span {
    line-height: 21px !important;
  }
}
.side:hover .side-prom {
  opacity: 1;
  display: block;
}
.side-proma div {
  line-height: 18px;
  font-size: 14px;
  color: #171c61;

  margin-left: 0px;
}
.side-proma img {
  width: 110px;
  height: 110px;
  margin-left: 0px;
  margin-top: -8px;
}
.side:hover .side-proma {
  opacity: 1;
  display: block;
}
.up {
  height: 40px;
  width: 40px;
  background-color: transparent;
  border-radius: 150px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  text-align: center;
  line-height: 40px;
  color: #171c61;
  img {
    width: 40px;
    height: 40px;
  }
}
.up-tp {
  right: 60px !important;
  bottom: 170px;
}
.el-backtop {
  position: absolute;
  right: 60px !important;
}
.imgb {
  display: none;
  margin-left: 6px;
  margin-top: -1px;
}

.side-proma span {
  text-align: left;
  display: inline-block;
  color: #707070;
  font-size: 12px;
  width: 100%;
  padding-left: 15px;
  line-height: 20px;
  i {
    color: #171c61;
  }
}
.my {
  text-align: left;
  padding-left: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
