<template>
  <!-- 封装底部样式 -->
  <div>
    <div style=" border-bottom: 1px solid #afafaf;margin: 50px 0;"></div>
    <div class="titbox">
      <!-- <div class="cba">友情链接：<span @click="lj">太一积木</span></div> -->

      <div class="top-box">
        <div>
          <img style="width:268px;hight:120px;" :src="img" alt="" />
          <div style="margin-top: 40px;"></div>
        </div>
        <ul class="lxwm">
          <li style="font-size:18px;color:#000;font-weight:600">联系我们</li>
          <el-tooltip
            class="item"
            effect="dark"
            content="点击复制邮箱"
            placement="top"
          >
            <li @click="copyUrl(link3)">业务合作： {{ link3 }}</li>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="点击复制"
            placement="top"
          >
            <li>
              联系电话:
              <span @click="copyUrl(link2)"> {{ link2 }} </span> \<span
                @click="copyUrl(link1)"
              >
                {{ link1 }}
              </span>
            </li>
          </el-tooltip>


        </ul>
        <ul class="lxwm">
          <div style="font-size:18px;color:#000;font-weight:600">相关链接</div>
         
        </ul>

        <ul class="lxwm">
          <div style="font-size:18px;color:#000;font-weight:600">
            关注我们
          </div>
          <li>
            <img
              style="width:100px"
              src="../../assets/images/tykf.png"
              alt=""
            />
          </li>
          <li style="text-align: center;margin:-10px">官方微信公众号</li>
          <li style="text-align: center">微信扫码关注</li>
        </ul>
      </div>
      <div style=" border-bottom: 1px solid #afafaf;margin-top: 0px;"></div>
      <div class="bottomba">
        <div>
          <!-- <span>公司简介</span>
          <el-divider direction="vertical"></el-divider>
          <span>网站地图</span>
          <el-divider direction="vertical"></el-divider>
          <span>隐私政策</span>
          <el-divider direction="vertical"></el-divider>
          <span>法律声明</span> -->
        </div>
        <div>
          <div class="titbox-bottom">
            <a
              target="_blank"
              href="https://www.beian.gov.cn/#/portal/webSearch?code=33011002017394\"
              style="display:inline-block;text-decoration:none;height:30px;line-height:30px;"
            >
           
              <p
                style="float:right;height:30px;line-height:52px;margin: 0px 0px 0px 5px; "
              >
                浙公网安备 33011002017394
              </p>
            </a>
            <el-divider direction="vertical"></el-divider>
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >浙ICP备2023038025号-1</a
            >
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { banner } from "../../api/api";
export default {
  data() {
    return {
      link2: "13157913141",
      link1: "18480720159",
      link3: "business@smightvision.net",
      link4: "浙江省杭州市余杭区五常街道高顺路8号1栋233室",
      dynamic: 0,
      screenWidth: "", //窗口宽度
      img: require("../../assets/images/log.jpg"),
    };
  },
  created() {
    this.logo();
  },
  mounted() {
    if (document.body.clientWidth > 1800) {
      this.dynamic = 1;
    } else {
      this.dynamic = 0;
    }
  },

  methods: {
    async logo() {
      let param = {
        type: 1,
      };
      const { data: res } = await banner(param);
      if (res.data.length > 0) {
        this.img = res.data[0].imgUrl;
      }

      // this.totale = res.data.total;
    },
    copyUrl(data) {
      let url = data;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "已成功复制到剪切板",
        type: "success",
      });
      oInput.remove();
    },
    lj() {
      window.open("https://www.taiyijimu.com/", "_blank");
    },
    fw(e) {
      if (this.$route.query.tape == 2) {
        this.$router.push({
          path: "/fw",
          query: {
            tape: 2,
          },
        });
        sessionStorage.setItem("fw", e);
        window.location.reload();
      } else {
        this.$router.push({
          path: "/fw",
          query: {
            tape: 2,
          },
        });
        sessionStorage.setItem("fw", e);
      }
    },
  },
  // 屏幕检测变化
};
</script>

<style lang="less" scoped>
.colorChangeae {
  height: 86px !important;
}
.titbox {
  min-width: 1200px;
  overflow: auto;
  width: 100%;

  background-color: #fff;

  position: relative;
}
.top-box {
  width: 1160px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.lxwm {
  div {
    font-size: 20px;
    margin-bottom: 20px;
    cursor: default;
  }
  li:first-child {
    font-size: 16px;
    margin-bottom: 11px;
    cursor: default;
  }
  li {
    cursor: pointer;

    font-size: 14px;
    margin: 12px 0;
  }
}
.blue {
  color: red !important;
}
.lxwma {
  display: flex;
  flex-wrap: wrap;
  height: 8rem;
  max-height: 8.57rem;
  min-height: 93px;
  flex-direction: column;
  position: relative;
  margin-top: 40px;
  width: 28rem;
  div {
    position: absolute;
    top: -35px;

    font-size: 20px;
    margin-bottom: 25px;
    cursor: default;
  }
  li {
    cursor: pointer;

    min-width: 88px;
    font-size: 12px;
    margin: 5px 0;
    margin-right: 24px;
  }
}
.imgurl {
  display: flex;
  img {
    width: 85px;
    height: 85px;
    margin: 0 20px;
  }
}
.titbox-bottom {
  width: 100%;
  font-size: 12px;
  text-align: center;
}
a {
  color: #5e646a;
}

a {
  text-decoration: none;
}

.router-link-active {
  text-decoration: none;
}
.imgtext {
  margin: 10px 0 !important;
  font-size: 12px !important;
  text-align: center;
}
.cba {
  cursor: pointer;
  width: 1200px;
  margin: 0 auto;
}
.bottomba {
  width: 1200px;
  margin: 20px auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
