/**
 * 封装 axios 请求模块
 * 接口开头全部小写
 * 增 add**
 * 删 delete**
 * 改 updata**
 * 查 get**
 * const acc = 'http://172.16.0.241:9090/website/'
 * const acc = 'https://www.smightvision.net/website/'
 */
import axios from "axios"
import common from "../assets/common"
import store from '../store/index'
import router from '../router/index'
import { MessageBox } from 'element-ui';
import { Message } from 'element-ui';
const acc = 'https://www.smightvision.net/website/'
const request = axios.create({
  withCredentials: true,
  baseURL: acc,
})

window.sessionStorage.setItem('bsle', acc)

// request拦截器
request.interceptors.request.use(config => {
  //接口携带token
  config.headers['token'] = window.localStorage.getItem('token')
  // config.headers['content-type'] = 'application/json; charset=utf-8'
  // config.headers['Access-Control-Allow-Credentials'] = 'true'
  // config.headers['Access-Control-Allow-Methods'] = 'POST, GET, OPTIONS, DELETE'


  if (common.getItem('token')) {

  }
  return config
}, error => {
  Promise.reject(error)
})

let flag = 0
request.interceptors.response.use(function (response) {
  // console.log(123, response);
  // const code = response.data.code || 200
  store.commit('setToken', response.headers['new-token'])

  if (response.status == 401) {

    flag++

    if (flag == 1) {
      // debugger

      MessageBox('登录状态已过期，请重新登录?', '系统提示', {
        confirmButtonText: '确定',
        type: 'warning'

      }).then(() => {

        router.replace({
          path: '/login'
        })
        flag = 0

        return response;
      }).catch(err => { });
    }


  }
  // else if (response.status == 500) {

  //   Message({
  //     message: '操作失败',
  //     type: 'error'
  //   });
  //   debugger
  // } 
  else {
    // Message({
    //   message: '操作成功',
    //   type: 'success'
    // });
    return response;
  }



}, error => {
  // console.log(error == 'Error: Request failed with status code 401');
  if (error == 'Error: Request failed with status code 401') {
    MessageBox('登录状态已过期，请重新登录?', '系统提示', {
      confirmButtonText: '确定',
      type: 'warning'

    }).then(() => {

      router.replace({
        path: '/login'
      })
      flag = 0

      return response;
    }).catch(err => { });
  }

  return Promise.reject(error);
})





export default request




function comfirm() {


}