<!-- 吸顶 -->
<template>
  <div class="scroll">
    <div class="nav" id="up">
      <div class="cont">
        <div>
          <img
            style="cursor: pointer;"
            :src="this.img"
            alt=""
            @click="shouye()"
          />
        </div>
        <div class="cba">
          

          <span
            v-for="(item, index) in title"
            :key="index"
            :class="{ colorChangeae: index == dynamic }"
            @click="gace(item, index)"
          >
            <span>
              {{ item.test }}
            </span>
            <!-- <transition name="el-zoom-in-top">
              <div v-show="show2" class="xtbox">
              
              </div>
            </transition> -->
          </span>
        </div>
        <!-- <div class="input">
          <div v-if="show == 0" style="width:70px" @click="login">
            <i class="el-icon-user"></i>登录
          </div>
          <div
            v-if="show == 1"
            style="width:180px;display:flex;    align-items: center;"
          >
            <span> 欢迎，{{ name }} </span> <span @click="tuivchu">退出</span>
          </div>
          <el-input placeholder="请输入搜索内容" v-model="input">
            <i
              @click="sunmat"
              slot="suffix"
              class="el-input__icon el-icon-search"
            ></i>
          </el-input> 
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { taggetList, banner } from "../../api/api";

export default {
  data() {
    return {
      show2: false,
      show: 0,
      title: [
       
      ],

      dynamic: 0,
      img: require("../../assets/images/log.jpg"),
      input: "",
      i: "",
      name: "",
    };
  },

  components: {},

  mounted() {
    var div = document.getElementById("up");
    div.style.setProperty("color", "#fff");
    window.addEventListener("scroll", this.watchScroll);
  },
  created() {
    console.log(localStorage.getItem("usename"));
    if (localStorage.getItem("usename") !== null) {
      this.name = localStorage.getItem("usename");
      this.show = 1;
    }
    this.bannera();
    this.logo();
    if (this.$route.query.tape !== undefined) {
      this.dynamic = this.$route.query.tape;
    } else {
      this.dynamic = 8;
    }
  },
  methods: {
    tuivchu() {
      localStorage.removeItem("usename");
      localStorage.removeItem("token");

      this.show = 0;
      this.$router.push("/");
    },
    login() {
      this.$router.push("/login");
    },
    async logo() {
      let param = {
        type: 1,
      };
      const { data: res } = await banner(param);
      if (res.data.length > 0) {
        this.img = res.data[0].imgUrl;
      }

      // this.totale = res.data.total;
    },
    async bannera() {
      let param = {};
      const { data: res } = await taggetList(param);
      let a = res.data;

      if (res.data.length > 0) {
        let b = [];
        a.forEach((e) => {
          let c = {
            test: e.title,
            ptah: e.jumpUrl,
          };
          b.push(c);
        });
        this.title = b;
      }
      // this.imgac = res.data;
      // this.totale = res.data.total;
    },
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var div = document.getElementById("up");

      //  当滚动超过 90 时，实现吸顶效果
      if (scrollTop > 60) {
        div.style.setProperty("background-color", "#fff");
        div.style.setProperty("color", "#000b88");
        // this.img = require(this.img);
      } else {
        div.style.setProperty("background-color", "#fff");
        div.style.setProperty("color", "#000b88");
        // this.img = require(this.img);
      }
    },
    showmouseover(i) {
      this.show2 = true;

      this.dynamic = i;
    },
    mouseoutshow(i) {
      this.show2 = false;
      // this.dynamic = this.$route.query.tape;
    },
    gace(item, index) {
      //点击添加字体颜色，其他的删除class名称

      this.$router.push({
        path: item.ptah,
        query: {
          tape: index,
        },
      });
      this.dynamic = index;
    },
    shouye() {
      //点击添加字体颜色，其他的删除class名称

      this.$router.push({
        path: "/",
      });
    },
    sunmat() {
      console.log(!this.input);
      if (!this.input !== true) {
        if (this.$route.query.tape == 4) {
          window.location.reload();
          sessionStorage.setItem("sxw", this.input);

          this.$router.push("/news?tape=3");
        } else {
          sessionStorage.setItem("sxw", this.input);

          this.$router.push("/news?tape=3");
        }
      } else {
      }
    },
  },
};
</script>
<style lang="less" scoped>
// .scroll {
// }
.colorChangeae {
  span {
    color: #fff;
  }

  font-weight: 600;
  background-color: #000b88;
  border-radius: 50px 50px 50px 50px;
}
.colorChangeae1 {
  color: #fff;
  font-weight: 600;
  background-color: #000b88;
  border-radius: 50px 50px 50px 50px;
}

.nav {
  width: 100%;
  height: 120px;
  font-weight: 600;
  background-color: #fff;
  box-sizing: border-box;
  color: #000b88 !important;
  text-align: center;
  position: fixed;
  top: 0;
  z-index: 999;
  font-size: 18px;
}

.nav:hover {
  color: #000b88 !important;
}
// .nav:hover .cont img {
//   content: url(`this.img`);
// }
.cont {
  width: 1360px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    display: inline-block;
    width: 140px;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
  }
  img {
    width: 268px;
    height: 120px;
    vertical-align: text-top;
  }
}
.cba {
  position: relative;
  margin: 10px;

}
.ccca {
  position: absolute;
  top: 0;
  left: 280px;
  width: 140px;
  height: 80px;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
}
.ccca:hover .xlk {
  display: block;
}
.xlk {
  position: absolute;
  top: 80px;
  width: 140px;
  left: 0;
  height: 340px;
  background-color: #fff;
  display: none;
}
.input {
  margin-top: 9px;
  display: flex;
  width: 200px;
  align-items: center;
  font-size: 16px !important;
  font-weight: 600;
  cursor: pointer;
}
/deep/.el-input__inner {
  border-radius: 40px;
  text-align: center;
  background-color: #efefef;
}
a {
  color: #afafafff;
}

a {
  text-decoration: none;
}
li {
  height: 40px;
}
.xtbox {
  background-color: #000b88;
  display: flex;
  opacity: 0.5;
  width: 100%;
  height: 50px;
  z-index: 999;
  position: absolute;
  bottom: -50px;
}
/deep/.el-dropdown-menu {
  ul {
    color: #fff;
  }
}
</style>
